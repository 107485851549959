import React from 'react'
import { connect } from 'react-redux'


import { actions as ConsultantActions } from '../../../redux/ConsultantRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'

import { Modal } from "../../../components/atoms/Modal/Modal"

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  removing: WSSelectors.pending(state, 'consultant.archive'),
  removed: WSSelectors.resolved(state, 'consultant.archive'),
})

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(ConsultantActions.archiveConsultant(id)),
  resetRemoveAction: () => dispatch(WSActions.reset('consultant.archive')),
})

const ManagerConsultantRemove = ({ id, onClose, remove }) => {
  const handleSubmit = () => {
    remove(id);
    onClose();
    window.location.reload()
  }

  return (
    <Modal
      onClosePopin={onClose}
      openPopin={true}
      title={"Archiver compte consultant"}
      content={"Voulez-vous vraiment archiver ce consultant ?"}
      cancelButton={"Annuler"}
      actionButton={"Supprimer"}
      handleActionButton={handleSubmit}
    />
  )

}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerConsultantRemove)