import React, { useState, useEffect, useRef } from 'react';

const IframeChecker = ({ url, aspectRatio = 16 / 9 }) => {
  const [contentType, setContentType] = useState(null);
  const [height, setHeight] = useState(0);
  const [error, setError] = useState(null);
  const containerRef = useRef(null);

  // Met à jour la hauteur en fonction du ratio d'aspect
  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setHeight(width / aspectRatio);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [aspectRatio]);

  // Vérifie si l'URL est valide et récupère le type de contenu
  useEffect(() => {
    let isMounted = true; // Variable pour suivre l'état du montage du composant

    const fetchContentType = async () => {
      try {
        if (!url) {
          throw new Error('URL non spécifiée');
        }
        const response = await fetch(url, { method: 'HEAD' });
        if (response.ok) {
          const type = response.headers.get('content-type');
          if (isMounted) {
            setContentType(type);  // Met à jour l'état seulement si le composant est monté
            setError(null);
          }
        } else {
          throw new Error('URL non valide ou ressource introuvable');
        }
      } catch (error) {
        if (isMounted) {
          setContentType(null);
          setContentType('url');

          // setError('Erreur : L\'URL est invalide ou la ressource est inaccessible.');
        }
      }
    };

    if (url) {
      fetchContentType();

    }

    return () => {
      isMounted = false; // Marque le composant comme démonté lors du nettoyage
    };
  }, [url]);

  // Reste du code...
  const renderContent = () => {
    if (error) {
      return (
        <div
          style={{
            padding: '1rem',
            borderRadius: '8px',
            backgroundColor: '#ffe6e6',
            color: '#ff4d4d',
            textAlign: 'center',
            fontWeight: 'bold',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <p>{error}</p>
        </div>
      );
    }

    if (!contentType) {
      return <p>Chargement du contenu...</p>;
    }

    // Gestion des images, vidéos, audio, PDF, etc.
    if (contentType.startsWith('image/')) {
      return (
        <img
          src={url}
          alt="Contenu"
          style={{
            width: '100%',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        />
      );
    }

    if (contentType.startsWith('video/')) {
      return (
        <video
          controls
          style={{
            width: '100%',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <source src={url} type={contentType} />
          Votre navigateur ne supporte pas la lecture de cette vidéo.
        </video>
      );
    }

    if (contentType.startsWith('audio/')) {
      return (
        <audio
          controls
          style={{
            width: '100%',
            marginTop: '1rem',
          }}
        >
          <source src={url} type={contentType} />
          Votre navigateur ne supporte pas la lecture de cet audio.
        </audio>
      );
    }

    if (contentType === 'application/pdf') {
      return (
        <iframe
          src={url}
          title="Document PDF"
          style={{
            width: '100%',
            height: `${height}px`,
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        />
      );
    }

    if (
      contentType.startsWith('text/') ||
      contentType === 'application/json' ||
      contentType === 'application/xml'
    ) {
      return (
        <iframe
          src={url}
          title="Contenu textuel"
          style={{
            width: '100%',
            height: `${height}px`,
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        />
      );
    }

    return (
      <iframe
        src={url}
        title="Contenu non spécifique"
        style={{
          width: '100%',
          height: `${height}px`,
          border: 'none',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
        allowFullScreen
      />
    );
  };

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      {renderContent()}
    </div>
  );
};

export default IframeChecker;


// import React, { useState, useEffect, useRef } from 'react';

// const IframeChecker = ({ url, aspectRatio = 16 / 9 }) => {
//   const [iframeLoaded, setIframeLoaded] = useState(null);
//   const [isEmbeddable, setIsEmbeddable] = useState(false);

//   const [height, setHeight] = useState(0);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const updateHeight = () => {
//       console.log('updateHeight', containerRef.current);

//       if (containerRef.current) {
//         const width = containerRef.current.offsetWidth;
//         setHeight(width / aspectRatio);
//         console.log('updateHeight', width, height, width / aspectRatio);
//       }
//     };

//     // Ajuster la hauteur au chargement et lors des redimensionnements de la fenêtre
//     updateHeight();
//     window.addEventListener('resize', updateHeight);

//     return () => {
//       window.removeEventListener('resize', updateHeight);
//     };
//   }, [aspectRatio, iframeLoaded]);

//   const embeddableMimeTypes = [
//     'text/html',
//     'image/jpeg',
//     'image/png',
//     'image/gif',
//     'image/webp',
//     'image/svg+xml',
//     'application/pdf',
//     'video/mp4',
//     'video/webm',
//     'video/ogg',
//     'audio/mpeg',
//     'audio/ogg',
//     'audio/wav',
//     'text/plain',
//     'text/css',
//     'text/javascript',
//     'application/xml',
//     'application/json',
//   ];

//   useEffect(() => {
//     const checkEmbeddable = async () => {
//       try {
//         const response = await fetch(url, { method: 'HEAD' });
//         const contentType = response.headers.get('content-type');

//         // Vérifiez si le type de contenu est embeddable
//         if (contentType && embeddableMimeTypes.includes(contentType)) {
//           setIsEmbeddable(true);
//         } else {
//           setIsEmbeddable(false);
//         }
//       } catch (error) {
//         setIsEmbeddable(false);
//       }
//     };

//     checkEmbeddable();
//   }, [url]);

//   useEffect(() => {
//     if (isEmbeddable === false) {
//       setIframeLoaded(false);
//       return;
//     }

//     const iframe = document.createElement('iframe');
//     iframe.style.display = 'none'; // Ne pas afficher l'iframe
//     iframe.src = url;

//     const handleLoad = () => {
//       setIframeLoaded(true);
//       document.body.removeChild(iframe);
//     };

//     const handleError = () => {
//       setIframeLoaded(false);
//       document.body.removeChild(iframe);
//     };

//     iframe.onload = handleLoad;
//     iframe.onerror = handleError;

//     document.body.appendChild(iframe);

//     // Nettoyage
//     return () => {
//       if (document.body.contains(iframe)) {
//         document.body.removeChild(iframe);
//       }
//     };
//   }, [url, isEmbeddable]);

//   if (iframeLoaded === null) {
//     return <p>Checking iframe...</p>;
//   }

//   return (
//     <div>
//       {url && (
//       iframeLoaded ? (
//         <div ref={containerRef} style={{ width: '100%' }}>
//       <iframe
//         src={url}
//         title={'Contenu'}
//         style={{
//           width: '100%',
//           height: `${height}px`,
//           border: 'none',
//           borderRadius: '8px',
//           boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//         }}
//         allowFullScreen
//       />
//     </div>
//       ) : (
//         <p>Ce contenu ne peut pas être affiché dans ce cadre, car le type de document n'est pas pris en charge.</p>
//       ))}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     position: 'relative',
//     width: '100%',
//     backgroundColor: '#f9f9f9',
//     borderRadius: '8px',
//     overflow: 'hidden',
//     boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//   },
//   iframe: {
//     width: '100%',
//     height: '100%', // Commence par 100% mais sera redimensionnée dynamiquement
//     border: 'none',
//     borderRadius: '8px',
//   },
// };

// export default IframeChecker;