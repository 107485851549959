import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollchor from 'react-scrollchor';
import CircleButton from '../../components/atoms/CircleButton/CircleButton';
import BigCombo from '../../components/molecules/BigCombo/BigCombo';
// import InfoPage from '../../components/molecules/InfoPage/InfoPage'
// import SectionTitle from '../../components/molecules/SectionTitle/SectionTitle'
import BeneficiaryList from '../../containers/beneficiary/BeneficiaryList/BeneficiaryList';
import ConsultantBeneficiaryRemove from '../../containers/consultant/ConsultantBeneficiaryRemove/ConsultantBeneficiaryRemove';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import TopSearch from '../../containers/global/TopSearch/TopSearch';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout';
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux';
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import {
  actions as ConsultantActions,
  selectors as ConsultantSelector,
} from '../../redux/ConsultantRedux';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import { useNavigation } from '../../hooks/useNavigation';
import LineMenu from '../../components/organisms/LineMenu/LineMenu';
import './ConsultantMyBeneficiariesPage.scss';

const ConsultantModulesPage = () => {
  const dispatch = useDispatch();
  const { pathname, redirect } = useNavigation();
  const hash = useSelector(NavigationSelectors.hash);
  const beneficiaries = useSelector(BeneficiarySelectors.beneficiaries);
  const beneficiariesFilter = useSelector(ConsultantSelector.beneficiariesFilter);
  const user = useSelector(UserSelectors.user);
  const [beneficiaryToDelete, setBeneficiariesToDelete] = useState();
  const [scope, setScope] = useState('primary');

  const sortOptions = [
    {
      label: 'Ordre alphabétique décroissant (Prénom)',
      value: 'first_name_desc',
    },
    {
      label: 'Ordre alphabétique croissant (Prénom)',
      value: 'first_name_asc',
    },
    {
      label: 'Ordre alphabétique décroissant (Nom)',
      value: 'last_name_desc',
    },
    {
      label: 'Ordre alphabétique croissant (Nom)',
      value: 'last_name_asc',
    },
  ];

  const sortQuery = {
    first_name_asc: {
      direction: 'asc',
      attribute: 'first_name',
    },
    first_name_desc: {
      direction: 'desc',
      attribute: 'first_name',
    },
    last_name_asc: {
      direction: 'asc',
      attribute: 'last_name',
    },
    last_name_desc: {
      direction: 'desc',
      attribute: 'last_name',
    },
  };

  const scrollRef = useRef();

  const handleAddNew = () => {
    redirect(pathname?.replace('/me', '/new'));
  };

  const handleExport = () => {
    dispatch(BeneficiaryActions.exportBeneficiaries(beneficiariesFilter));
  };

  const handleSearch = (value) => {
    dispatch(
      ConsultantActions.setBeneficiariesFilter({
        ...beneficiariesFilter,
        q: value,
      }),
    );
  };

  const handleBigComboChange = ({ value }) => {
    dispatch(
      ConsultantActions.setBeneficiariesFilter({
        ...beneficiariesFilter,
        status: value.value,
      }),
    );
  };

  const handleDeleteBeneficiary = ({ id }) => {
    setBeneficiariesToDelete(id);
  };

  const handleCloseConfirmRemovePopin = () => {
    setBeneficiariesToDelete(null);
  };

  const handleSortChange = ({ value }) => {
    const { attribute, direction } = sortQuery[value?.value];

    dispatch(
      ConsultantActions.setBeneficiariesFilter({
        ...beneficiariesFilter,
        orderAttribute: attribute,
        orderDirection: direction,
      }),
    );
  };

  const handleTabChange = useCallback(
    (scope) => {
      setScope(scope);
      redirect(`${pathname}#${scope}`);
    },
    [pathname, redirect],
  );

  useEffect(() => {
    dispatch(BeneficiaryActions.getBeneficiaries(beneficiariesFilter));
  }, [beneficiariesFilter, dispatch]);

  // const getAllMyBeneficiairies = () => {
  //   BeneficiaryActions.getBeneficiaries(beneficiariesFilter)
  // }

  useEffect(() => {
    if (hash && document.getElementById(hash)) {
      // eslint-disable-next-line no-unused-expressions
      scrollRef.current?.simulateClick();
    }
  }, [hash]);

  const renderHeader = () => {
    const statuses = [
      { key: '', value: 'Tous' },
      { key: 'created', value: 'Prospect' },
      { key: 'wait_financer', value: 'En attente de financement' },
      { key: 'in_validation', value: 'En attente de validation' },
      { key: 'in_progress', value: 'En cours' },
      { key: 'follow_up', value: 'Suivi professionel' },
      { key: 'archived', value: 'Historique' },
    ];
    const options = statuses.map((item) => ({
      value: item.key,
      label: item.value,
    }));

    const selected = options.find(({ value }) => value === beneficiariesFilter.status);

    return (
      <div className="filters">
        <BigCombo value={selected} options={options} onChange={handleBigComboChange} />
        <BigCombo
          options={sortOptions}
          onChange={handleSortChange}
          dropDownProps={{
            placeholder: 'Trier',
          }}
        />
        <span className="separator" />
      </div>
    );
  };

  const renderRemovePopin = () => {
    if (!beneficiaryToDelete) {
      return null;
    }

    return (
      <ConsultantBeneficiaryRemove
        id={beneficiaryToDelete}
        onClose={() => handleCloseConfirmRemovePopin()}
      />
    );
  };

  const renderTalents = (talents) => {
    return (
      <>
        <div className="header-actions">
          {scope === 'primary' && (user.role === 'consultant' || user.role === 'manager') ? (
            <CircleButton icon="plus" onClick={handleAddNew} />
          ) : (
            <div></div>
          )}
          {talents.length > 0 && (
            <PrimaryButton
              id="beneficiaries-list-export"
              label={'Exporter'}
              onClick={handleExport}
            />
          )}
        </div>

        {renderRemovePopin()}

        <div id="primary" className="my-beneficiaries">
          {renderHeader()}
          {talents.length > 0 && (
            <>
              <BeneficiaryList
                beneficiaries={talents}
                onDeleteClick={handleDeleteBeneficiary}
                isPrimary
              />
            </>
          )}
        </div>
      </>
    );
  };

  const renderSharedTalents = (talents) => {
    return (
      <div>
        {/* {renderRemovePopin()} */}
        <div id="shared" className="my-beneficiaries">
          {renderHeader()}
          {talents.length > 0 && <BeneficiaryList beneficiaries={talents} />}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const myBeneficiaries = [];
    const sharedBeneficiaries = [];
    beneficiaries.forEach((benef) => {
      if (user.role === 'manager') {
        myBeneficiaries.push(benef);
        return true;
      }
      const isPrimary = benef.consultants.some(
        ({ id, is_primary }) => is_primary && id === user.id,
      );

      if (isPrimary) {
        myBeneficiaries.push(benef);
      } else {
        sharedBeneficiaries.push(benef);
      }
    });
    return (
      <>
        {scope === 'primary' && renderTalents(myBeneficiaries)}
        {scope === 'shared' && renderSharedTalents(sharedBeneficiaries)}
      </>
    );
  };

  const aside = user.role === 'consultant' ? <ConsultantPageAside /> : <ManagerPageAside />;

  const searchBar = () => (
    <TopSearch value={beneficiariesFilter.q} onSearch={handleSearch} debounceDuration={0} />
  );

  const tabs = () => {
    let tabs = [];

    if (user.role === 'consultant') {
      tabs = [
        { id: 'primary', label: 'Mes talents' },
        { id: 'shared', label: 'Mes talents partagés' },
      ];
    } else if (user.role === 'manager') {
      tabs = [{ id: 'primary', label: 'Nos talents' }];
    }
    return (
      <LineMenu value={scope} links={tabs} onChange={handleTabChange} components={searchBar()} />
    );
  };

  const content = <TabbedContentLayout header={tabs()} content={renderContent()} />;

  if (!user) {
    return <></>;
  }

  return (
    <div className="page">
      <div className="consultant-my-beneficiaries-page">
        <Scrollchor ref={scrollRef} to={hash} />
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    </div>
  );
};

export default ConsultantModulesPage;
