/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import ConversationItem from "./ConversationItem";
import { connect } from "react-redux";
import { selectors as ChatSelectors } from "../../redux/ChatRedux";
import Icon from "../atoms/Icon/Icon";
import { getUser } from "../../api/userAPI";
import LoadingSpinner from "../atoms/LoadingSpinner/LoadingSpinner";

const mapStateToProps = (state) => ({
    socket: ChatSelectors.setWebSocket(state),
    globalSocket: ChatSelectors.setGlobalSocket(state)
})

function ConversationList(props) {
    const [conversationList, setConversationList] = useState([]);
    const [lastMessage, setLastMessage] = useState("");
    const [sync, setSync] = useState(false);
    const socket = props.socket;
    const globalSocket = props.globalSocket;
    const role = props.userrole;
    const conversationRef = useRef();
    const conversationMobileRef = useRef();
    const buttonClose = useRef();
    const [loading, setLoading] = useState(false);

    const handleClick = (e) => {
        const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationContainerList.classList.add("conversation-mobile");
        conversationListMobile.classList.add("conversation-mobile");
    }

    const handleOutsideClick = useCallback((e) => {
        if (conversationRef.current && !conversationRef.current.contains(e.target) && conversationMobileRef.current && !conversationMobileRef.current.contains(e.target)) {
            const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
            const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

            conversationContainerList.classList.remove("conversation-mobile");
            conversationListMobile.classList.remove("conversation-mobile");
        }
    }, [])

    const handleClose = () => {
        const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationContainerList.classList.remove("conversation-mobile");
        conversationListMobile.classList.remove("conversation-mobile");
    }

    const syncConversations = () => {
        // use role variable
        axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/room/create/" + role, {
            params: {
                id_user: props.idUser
            }
        }).then(function (res) {
            setSync(true);
        }).catch(function (err) {
        })
    }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const handleSearchKeyUp = (e) => {
        e.preventDefault();
        const normalizedSearchValue = removeAccents(e.target.value).toLowerCase();

        const updatedConversations = conversationList.map(conversation => {
            const parsed_name = parseInt(conversation.acquaintance_id) === parseInt(props.idUser) ? conversation.user_name : conversation.acquaintance_name;
            const name = parsed_name == null ? "Manager " + conversation.office_name : parsed_name;
            return {
                ...conversation,
                name: name
            }
        })

        const matchedResults = updatedConversations.filter(conversation => {
            if (conversation.name !== null) {
                const normalizedConversationName = removeAccents(conversation.name).toLowerCase();
                return normalizedConversationName.includes(normalizedSearchValue);
            }
            return false;
        });

        const unmatchedResults = updatedConversations.filter(conversation => {
            if (conversation.name !== null) {
                const normalizedConversationName = removeAccents(conversation.name).toLowerCase();
                return !normalizedConversationName.includes(normalizedSearchValue);
            }
            return true;
        });

        const sortedResults = [...matchedResults, ...unmatchedResults];

        setConversationList(sortedResults);

        if (normalizedSearchValue === "") {
            axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/rooms", {
                params: {
                    id_user: props.idUser
                }
            }).then(function (res) {
                setConversationList(res.data);
            }).catch(function (err) {
            })
        }
    }

    useEffect(() => {
        syncConversations();
    }, [])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/rooms", {
            params: {
                id_user: props.idUser
            }
        }).then(function (res) {
            setConversationList(res.data);
        }).catch(function (err) {
        })

        if (props.socketReady) {

            const handleSocketMessage = (e) => {
                const message = JSON.parse(e.data);

                if (message.type !== "pong") {
                    globalSocket.send(JSON.stringify(message));
                    if (message.type === "deletion") {
                        setLastMessage("<Supprimé...>");

                        axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/room/messages", {
                            params: {
                                room_key: message.room_key,
                                last: true
                            }
                        }).then(function (res) {
                            if (res.data[0] !== undefined) {
                                if (res.data[0].random_key === message.random_key) {
                                    setLastMessage("<Supprimé...>");
                                }
                            }
                        }).catch(function (err) {
                        })
                    } else {
                        globalSocket.send(JSON.stringify(message));
                        setLastMessage(message.content);
                    }
                }
            };

            const handleGlobalState = (e) => {
                const message = JSON.parse(e.data);

                if (message.type !== "pong") {
                    if (message.type === "deletion") {
                        setLastMessage("<Supprimé...>");
                    } else {
                        setLastMessage(message.content);
                    }

                    const room_key = message.room_key;

                    const sortedList = [...conversationList].sort((a, b) => {
                        if (a.room_key === room_key) {
                            return -1; // a passe avant b
                        }
                        if (b.room_key === room_key) {
                            return 1; // b passe avant a
                        }
                        return 0; // a et b restent inchangés
                    });

                    setConversationList(sortedList);
                }


            }

            socket.addEventListener("message", handleSocketMessage);
            globalSocket.addEventListener("message", handleGlobalState);

            return () => {
                socket.removeEventListener("message", handleSocketMessage);
                globalSocket.removeEventListener("message", handleGlobalState);
            };
        }

    }, [props, socket, globalSocket, sync, lastMessage])

    useEffect(() => {

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [handleOutsideClick])

    useEffect(() => {
        syncConversations()
    }, [])

    const [conversations, setConversations] = useState([]);

    useEffect(() => {
        setLoading(true);
        const fetchConversations = async () => {
            const results = await Promise.all(conversationList.map(async (data) => {
                const userId = parseInt(data.acquaintance_id) === parseInt(props.idUser) ? data.user_id : data.acquaintance_id;
                const { user } = await getUser(userId);

                const parsed_name = parseInt(data.acquaintance_id) === parseInt(props.idUser) ? data.user_name : data.acquaintance_name;
                const name = parsed_name == null ? "Manager " + data.office_name : parsed_name;

                return {
                    ...data,
                    name,
                    profile_picture: user.image_url === null ? null : user.image_url,
                };
            }));
            setConversations(results);
            setLoading(false);
        };

        fetchConversations();
    }, [conversationList]);

    return (
        <>
            <div ref={conversationRef} className="container-fluid conversation-container">
                {loading && <LoadingSpinner />}

                <div className="row">
                    <p className="mt-2 pt-3 conversation-title">Mes conversations</p>
                    <button ref={buttonClose} onClick={handleClose} className="close-conversation-container"></button>
                </div>
                <div className="conversation-search">
                    <Icon icon={Icon.icon.Search} />
                    <input type="text" className="conversation-search-input" onKeyUp={handleSearchKeyUp} />
                </div>
                {conversations.map(function (data) {

                    const parsed_name = parseInt(data.acquaintance_id) === parseInt(props.idUser) ? data.user_name : data.acquaintance_name;
                    const name = parsed_name == null ? "Manager " + data.office_name : parsed_name;
                    return (
                        <ConversationItem
                            key={data.id}
                            idConv={data.id}
                            roomKey={data.room_key}
                            photo={data.profile_picture}
                            name={name}
                            activeName={props.activeName}
                            conversation={data}
                            onData={props.onData}
                            lastMessage={lastMessage}
                            idUserToNotify={props.idUser}
                        />
                    )
                })}
            </div>
            <div className={`container-fluid conversation-container-mobile`}>
                <img ref={conversationMobileRef} src="../static/icons/users-list.svg" alt="user list" onClick={(e) => handleClick(e)} />
            </div>
        </>
    )
}

export default connect(mapStateToProps, null)(ConversationList);