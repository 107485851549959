import React, { useEffect, useState } from 'react';
import { Tag } from '../../atoms/Tag/Tag';
import DropStepZone from '../../molecules/DropStepZone/DropStepZone';
import CircleButton from '../../atoms/CircleButton/CircleButton';
import './ParcoursDocumentReturn.scss';
import { updateModule } from '../../../api/CourseApi';
import IframeChecker from '../IframeChecker/IframeChecker';
import moment from 'moment';

export const ParcoursDocumentReturn = ({ documentToReturn, isDocument, setNewModule, fetchModules = null }) => {
  const [fileUpload, setFileUpload] = useState();

  useEffect(() => {
  }, [fileUpload]);

  const cleanFileNameForURL = (fileName) => {
    const splitedName = fileName
          .normalize('NFD') // Normalise les accents
          .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques
          .replace(/[^\w.-]+/g, '-') // Remplace les caractères non valides par des tirets (y compris ’)
          .replace(/-+/g, '-') // Remplace les multiples tirets par un seul
          .replace(/^-+|-+$/g, '')
          .replace(' ', '_')
          .replace("'", '-')
          .toLowerCase().split('.'); // Convertit en minuscule pour uniformiser
          return (splitedName[0] + '-' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.' + splitedName[1]);
  };

  const getFileUpload = (file) => {
    const fileUploadTmp = cleanFileNameForURL(file.file.name);
    const reader = new FileReader();
    reader.onload = () => {
      documentToReturn.data[0].upload_return = reader.result;
      documentToReturn.data[0].upload_return_name = fileUploadTmp;
      documentToReturn.done = 3;
      setNewModule(documentToReturn);
      isDocument(fileUploadTmp);
      setFileUpload(fileUploadTmp);
    
    updateModule(documentToReturn).then(() => {fetchModules();});
    };
    reader.readAsDataURL(file.file);
    // setFileUpload(fileUploadTmp);
    // console.log(file, reader, fileUploadTmp);
  };

  const deleteFile = () => {
    setFileUpload();
    documentToReturn.data[0].url_return = null;
    documentToReturn.done = 1;
    isDocument(undefined);
    updateModule(documentToReturn);
  };

  const getFileName = () => {
    return fileUpload.split('/').pop();
  };

  useEffect(() => {
    if (documentToReturn.data[0].url_return) {
      setFileUpload(documentToReturn.data[0].url_return);
      documentToReturn.done = 3;
      updateModule(documentToReturn);
    } else {
      setFileUpload(null);
    }
  }, [documentToReturn]);

  const renderUploaded = () => {
    return (
      <div className="document-to-download">
        <span>Document à soumettre</span>
        <div className="download-container">
          <div className="download">
            <a href={fileUpload} download target="_blank">
              {getFileName()}
            </a>
          </div>
          <CircleButton icon={'trash'} onClick={deleteFile} />
        </div>
      </div>
    );
  };

  const renderDropFile = () => {
    return (
      <div>
        <p>Document à compléter</p>

        <DropStepZone label={'Glisser et déposer votre document ici'} onDrop={getFileUpload} />
      </div>
    );
  };

  const handleClickUpload = (event) => {
    const blob = new Blob([event], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = event;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const download = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';
    link.click();
    // fetch(url)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.download = filename;
    //     link.click();
    // })
    // .catch(error => console.error('Erreur de fetch :', error));
  };
  return (
    <div className="parcours-document-to-return">
      <div>
        <Tag state={documentToReturn.done} />
        <h1>{documentToReturn.title}</h1>
        <p>{documentToReturn.data[0].description}</p>
      </div>

      {documentToReturn.data[0].url ? (
        <>
          <p className="description">
            Téléchargez le document ici, puis effectuez les exercices qu'il contient. Une fois les
            exercices terminés, veuillez les soumettre ci-dessous pour finaliser ce module.
          </p>
          <div className="document-to-download">
            <span>Document à télécharger</span>
            <div className="download-container">
              <div className="download">
                <a href={documentToReturn.data[0].url} download target="_blank">
                  {documentToReturn.data[0].title}
                </a>
              </div>
              <CircleButton
                icon={'download'}
                onClick={() =>
                  download(documentToReturn.data[0].url, documentToReturn.data[0].title)
                }
              />
            </div>
          </div>
        </>
      ) : (
        <p className="description">
          Veuillez soumettre ci-dessous les documents complétés pour finaliser ce module.
        </p>
      )}
      <div style={{width: '100%'}}>
          <IframeChecker url={documentToReturn.data[0].url} />
          </div>
      {!fileUpload ? renderDropFile() : renderUploaded()}
      <div >
        
        <div style={{width: '100%'}}>{fileUpload && <IframeChecker url={fileUpload} />}</div>
        </div>

      
      

    </div>
  );
};
