import 'moment/locale/fr'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Page from '../../global/Page/Page'
import AgendaPageContainer from './AgendaPageContainer'
import HeaderConsultantTab from '../../../components/atoms/HeaderConsultantTab/HeaderConsultantTab'
import ImagePng from '../../../static/illustrations/Agenda.png'
import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary'
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'

class ConsultantBeneficiaryCalendarContainer extends Component {
  renderAside = () => {
    return <ConsultantBeneficiaryAside />
  }

  render() {
    const { beneficiary } = this.props
    return (
      <ConsultantBeneficiary
        renderAside={this.renderAside}
        disableSave
      >
        <div className="consultant-beneficiary-calendar-page">
          <HeaderConsultantTab title={'Agenda'} logo={null} description={"Retrouvez ici l'agenda personnel de votre talent"} illustration={ImagePng} />

          <AgendaPageContainer beneficiary={beneficiary}></AgendaPageContainer>

        </div>
      </ConsultantBeneficiary>
    )
  }
}

export default connect()(Page(ConsultantBeneficiaryCalendarContainer))